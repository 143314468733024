import React from 'react';
import './DigitalBusiness.css';
import businessImage from '../assets/digital.jpg'; // replace with your image path

const DigitalBusiness = () => {
    return (
        <div className="digital-business">
            <div className="content">
                <div className="skills">
                    <h3 className="section-title">OUR SKILLS</h3>
                    <h1 className="headline">Start your digital business now</h1>
                    <p className="description">Launch your digital journey today and watch your business soar.</p>
                    <div className="skill-bar">
                        <span>WEB DEVELOPMENT</span>
                        <div className="bar">
                            <div className="progress" style={{ width: '90%' }}></div>
                        </div>
                    </div>
                    <div className="skill-bar">
                        <span>DIGITAL MARKETING & SEO</span>
                        <div className="bar">
                            <div className="progress" style={{ width: '75%' }}></div>
                        </div>
                    </div>
                    <div className="skill-bar">
                        <span>WEB DESIGN</span>
                        <div className="bar">
                            <div className="progress" style={{ width: '80%' }}></div>
                        </div>
                    </div>
                    <div className="skill-bar">
                        <span>SEO Service</span>
                        <div className="bar">
                            <div className="progress" style={{ width: '65%' }}></div>
                        </div>
                    </div>
                    <div className="skill-bar">
                    <span>Branding</span>
                    <div className="bar">
                        <div className="progress" style={{ width: '65%' }}></div>
                    </div>
                </div>
                    <div className="skill-bar">
                    <span>Sales Funnel</span>
                    <div className="bar">
                        <div className="progress" style={{ width: '65%' }}></div>
                    </div>
                </div>
                </div>
            </div>
            <div className="image-section">
                <img src={businessImage} alt="Digital Business" />
            </div>
        </div>
    );
};

export default DigitalBusiness;
