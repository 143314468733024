import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './components/Pages/Home'
import About from './components/Pages/About'
import Contact from './components/Pages/Contact'
import Blog from './components/Blog'
import PrivacyPolicy from './components/Layout/PrivacyPolicy'

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  )
}

export default App
