import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import emailjs
import './ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '', // Added phone number field
        subject: '',
        message: ''
    });

    const [emailError, setEmailError] = useState(''); // State for email validation error
    const [phoneError, setPhoneError] = useState(''); // State for phone number validation error

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateEmail = (email) => {
        // Regular expression for validating email addresses (gmail or other common providers)
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone) => {
        // Regular expression to ensure phone number is exactly 10 digits
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate email
        if (!validateEmail(formData.email)) {
            setEmailError('Please enter a valid email address (e.g., Gmail or other valid domains).');
            return;
        }

        // Validate phone number
        if (!validatePhone(formData.phone)) {
            setPhoneError('Please enter a valid 10-digit phone number.');
            return;
        }

        setEmailError(''); // Clear any previous email errors
        setPhoneError(''); // Clear any previous phone errors

        // Replace with your own service ID, template ID, and user ID from EmailJS
        const serviceID = 'service_iyzi5ti';
        const templateID = 'template_yj48eed';
        const userID = 'CutmY8XvcA4UdqCdj';

        const templateParams = {
            name: formData.name,
            email: formData.email,
            phone: formData.phone, // Send phone number with the template
            subject: formData.subject,
            message: formData.message,
        };

        emailjs.send(serviceID, templateID, templateParams, userID)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Message sent successfully!');
            })
            .catch((error) => {
                console.error('FAILED...', error);
                alert('Failed to send message, please try again later.');
            });

        // Reset form after submission
        setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
        });
    };

    return (
        <div className="main">
        <div className="contact-container">
            <div className="left-section">
                <h2 className="heading">Contact us and get more information</h2>
                <p className="description">
                Feel free to contact us with any inquiries or questions. We're here to assist you and provide helpful information.
                </p>
            </div>
            <form className="form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    placeholder="Your name"
                    value={formData.name}
                    onChange={handleChange}
                    className="input"
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Your email"
                    value={formData.email}
                    onChange={handleChange}
                    className="input"
                    required
                />
                {emailError && <p className="error">{emailError}</p>} {/* Display email error */}
                <input
                    type="text"
                    name="phone"
                    placeholder="Your phone number"
                    value={formData.phone}
                    onChange={handleChange}
                    className="input"
                    required
                />
                {phoneError && <p className="error">{phoneError}</p>} {/* Display phone error */}
                <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="input"
                    required
                />
                <textarea
                    name="message"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    className="textarea"
                    required
                />
                <button type="submit" className="button">Send message</button>
            </form>
        </div>
        </div>
    );
};

export default ContactForm;
