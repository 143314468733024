import React from 'react';
import './Aboutdigitalservices.css';
import { FaCheckCircle } from 'react-icons/fa';
import image from '../assets/cpt-inner-img-about.webp';
import logo from '../assets/co-sponsor-01-dark.webp';

const Aboutdigitalservices = () => {
    return (
        <section className="digital-services">
            <div className="services1-grid">
                <div className="image-grid">
                    <img src={image} alt="Service 1" />
                </div>
                <div className="services-content">
                    <h4>WHAT WE DO</h4>
                    <h2>We offer you modern digital services</h2>
                    <p>
                    At G For Growth, we offer a comprehensive range of modern digital marketing services designed to boost your brand's online presence and drive business growth. From SEO and web development to social media marketing and branding, our tailored solutions focus on engaging your target audience, improving conversion rates, and delivering measurable results. By utilizing the latest tools and data-driven strategies, we help you stay ahead in the ever-evolving digital landscape.

                    </p>
                    <ul className="services-list">
                        <li><FaCheckCircle className="check-icon" />  
 	
                        SEO (Search Engine Optimization)</li>
                        <li><FaCheckCircle className="check-icon" />  
 	
                        Web Development</li>
                        <li><FaCheckCircle className="check-icon" />  
 	
                        Social Media Marketing</li>
                        <li><FaCheckCircle className="check-icon" />  
 	
                        Social Media Marketing</li>
                        <li><FaCheckCircle className="check-icon" />Graphic Design</li>
                        <li><FaCheckCircle className="check-icon" />Paid Advertising (PPC)</li>
                        <li><FaCheckCircle className="check-icon" /> 
 	
                        Email Marketing
                        </li>
                        <li><FaCheckCircle className="check-icon" /> 
 	
                        Sales Funnel Optimization</li>
                        <li><FaCheckCircle className="check-icon" /> 
 	
                        Content Marketing</li>
                        <li><FaCheckCircle className="check-icon" />Mobile App Development</li>
                    </ul>
                </div>
            </div>
            <div className="clients">

            </div>
        </section>
    );
};

export default Aboutdigitalservices;
