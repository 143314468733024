import React, { useEffect, useState, useRef } from 'react';
import './TeamSection.css';
import CountUp from 'react-countup';
import member1 from '../assets/cpt-testimonial-3.webp'; // replace with your actual image paths
import member2 from '../assets/cpt-testimonial-3.webp';
import member3 from '../assets/cpt-testimonial-3.webp';
import member4 from '../assets/cpt-testimonial-3.webp';

const TeamSection = () => {
    const [startAnimation, setStartAnimation] = useState(false);
    const statsRef = useRef(null);

    const stats = [
        { number: 280, label: 'Customers' },
        { number: 650, label: 'Completed Projects' },
        { number: 50, label: 'Team Members' },
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setStartAnimation(true);
                    observer.disconnect(); // Disconnect after triggering animation once
                }
            },
            { threshold: 0.3 } // Trigger when 30% of the element is visible
        );

        if (statsRef.current) {
            observer.observe(statsRef.current);
        }

        return () => {
            if (statsRef.current) {
                observer.unobserve(statsRef.current);
            }
        };
    }, []);

    return (
        <div className="team-section" ref={statsRef}>
            <div className="team-header">
                {/* Add your team header content here */}
            </div>
            <div className="team-stats">
                {stats.map((stat, index) => (
                    <div className="stat" key={index}>
                        <h2 className="stat-number">
                            {startAnimation ? (
                                <CountUp start={0} end={stat.number} duration={2} />
                            ) : (
                                0
                            )}
                            +
                        </h2>
                        <p className="stat-label">{stat.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamSection;
