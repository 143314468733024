import React from 'react'
import './Contactcontainer.css'
const Contactcontainer = () => {
    return (
        <div className='contactcontainer-main'>
            <h1>Contact</h1>
            <p>If you intertested in our services , please contact
                us and get more informations now</p>
        </div>
    )
}

export default Contactcontainer
