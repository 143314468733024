import React, { useEffect } from 'react';
import Navbar from '../Layout/Navbar';
import CallToAction from '../CallToAction';
import Footer from '../Layout/Footer';
import Aboutservices from '../Aboutservices';
import Contactcontainer from '../Contactcontainer';
import ContactForm from '../ContactForm';
import Aboutdigitalservices from '../Aboutdigitalservices';
import DigitalBusiness from '../DigitalBusiness';
import Map from './Map';

const Contact = () => {
    useEffect(() => {
        // Scrolls to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <Contactcontainer />
   {      /*   <DigitalBusiness />*/}
            <ContactForm />
            <Map />
    {     /*   <Aboutservices />
            <CallToAction />*/}
            <Footer />
        </>
    );
}

export default Contact;
