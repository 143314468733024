import React from 'react';
import './Topcontainer.css';
import banner from '../assets/homeepage.jpg';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Topcontainer = () => {
    return (
        <section className="topcontainer-main" style={{ overflowX: 'hidden',overflowY: 'hidden'}}>

            <motion.div 
                className="topcontainer-container"
                initial={{ opacity: 0 }} 
                whileInView={{ opacity: 1 }} 
                transition={{ duration: 0.8 }}
            >
                <motion.div 
                    className="topcontainer-content"
                    initial={{ opacity: 0, x: -100 }} 
                    whileInView={{ opacity: 1, x: 0 }} 
                    transition={{ duration: 0.8 }}
                >
                    <motion.div 
                        className="content-left"
                        initial={{ opacity: 0, x: -200 }} 
                        whileInView={{ opacity: 1, x: 0 }} 
                        transition={{ duration: 0.8 }}
                    >
                        <div className="content-top"></div>
                        <motion.div 
                            className="content-middle"
                            initial={{ opacity: 0, y: 50 }} 
                            whileInView={{ opacity: 1, y: 0 }} 
                            transition={{ duration: 0.8 }}
                        >
                            <h1>Next-generation Digital Services</h1>
                           
                            <p>G For Growth is a leading digital marketing agency based in Mumbai, dedicated to helping businesses thrive in the digital space. We offer tailored services in web development, graphic design, and digital marketing, including targeted SEO strategies, engaging social media campaigns, and responsive website design. Our team focuses on driving measurable results that lead to sustained growth.</p>
                       <p>With a strong emphasis on branding and customer engagement, we create solutions that resonate with your audience and enhance your business's visibility. Whether you're looking to increase organic traffic through SEO, optimize conversion rates with effective sales funnels, or elevate your brand identity, G For Growth is here to deliver custom strategies suited to your unique needs.</p>
                            <p>Let us help you unlock your business's full potential with innovative, data-driven strategies from a trusted digital marketing agency in Mumbai.</p>
                       </motion.div>

                        <motion.div 
                            className="content-bottom"
                            
                        >
                            <Link to='/contact'> 
                                <button>Get Started</button>
                            </Link>
                        </motion.div>
                    </motion.div>
                    <motion.div 
                        className="content-right"
                        initial={{ opacity: 0, x: 200 }} 
                        whileInView={{ opacity: 1, x: 0 }} 
                        transition={{ duration: 0.8 }}
                    >
                        <img src={banner} alt="" />
                    </motion.div>
                </motion.div>
            </motion.div>
        </section>
    );
};

export default Topcontainer;
