import React from 'react';
import './Services.css';
// Import your images
import webDevImg from './../assets/Frame.png'; // Update the path as needed
import graphicDesignImg from './../assets/Frame1.png'; // Update the path as needed
import digitalMarketingImg from './../assets/Frame2.png'; // Update the path as needed
import seoImg from './../assets/Frame3.png'; // Update the path as needed
import brandingImg from './../assets/Frame4.png'; // Update the path as needed
import salesFunnelImg from './../assets/Frame5.png'; // Update the path as needed

const Services = () => {
    return (
        <section className="services-main">
            <div className="services-container">
                <div className="services-header">
                    <p>Our services</p>
                    <h1>Grow your business with our Digital services</h1>
                </div>
                <div className="services-cards">
                    <div className="services-card">
                        <img src={webDevImg} alt="Web Development" className="services-icon" />
                        <h2>Web development</h2>
                        <p> 
 	
                        Professional web development services that design and build responsive, SEO-friendly websites for small businesses and enterprises. These custom web solutions enhance user engagement, improve website performance, and provide a seamless digital experience to drive business growth.</p>
                    </div>
                    <div className="services-card">
                        <img src={graphicDesignImg} alt="Graphic Design" className="services-icon" />
                        <h2>Graphic design</h2>
                        <p>Graphic design services for businesses looking to enhance brand identity through visually compelling, custom-made graphics. These professional design solutions help communicate brand messaging effectively and leave a lasting impression on the target audience, boosting brand awareness.</p>
                    </div>
                    <div className="services-card">
                        <img src={digitalMarketingImg} alt="Digital Marketing" className="services-icon" />
                        <h2>Digital marketing</h2>
                        <p>Comprehensive digital marketing services to increase online visibility and attract targeted traffic. Digital strategies include SEO for eCommerce businesses, paid social media advertising, and content marketing to drive user engagement and achieve measurable business growth.</p>
                    </div>
                    <div className="services-card">
                        <img src={seoImg} alt="SEO Services" className="services-icon" />
                        <h2>SEO Services</h2>
                        <p>SEO services tailored to improve search engine rankings for small businesses and startups. Advanced SEO techniques, including long-tail keyword optimization, high-quality link building, and on-page SEO audits, help drive organic traffic, increase brand visibility, and boost conversion rates.</p>
                    </div>
                    <div className="services-card">
                        <img src={brandingImg} alt="Branding" className="services-icon" />
                        <h2>Branding</h2>
                        <p>Branding services designed to help businesses create a distinct brand identity that resonates with their target audience. From logo design to comprehensive brand strategy, this approach ensures that businesses stand out from competitors and foster long-term customer loyalty.</p>
                    </div>
                    <div className="services-card">
                        <img src={salesFunnelImg} alt="Sales Funnel" className="services-icon" />
                        <h2>Sales Funnel</h2>
                        <p></p> 
 	
                        Custom sales funnel creation services that guide potential customers through every stage of the buyer's journey. This strategy nurtures leads, improves the customer experience, and increases sales conversions by addressing the needs of prospects from awareness to purchase.
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
